<template>
    <div>
        <b-overlay :show="loading">
            <h3>Ajout d'une bannière</h3>
            <b-form @submit.stop.prevent>
                <b-row>
                    <b-col cols="6">
                        <b-form-group label="Sélectionner une marque" label-for="input-select-brand">
                            <b-form-select
                                id="input-select-brand"
                                v-model="selectedBrand"
                                :options="sortedBrands"
                                value-field="code"
                                text-field="label"
                                :state="ruleSelectedBrand || !showFormError ? null : false"
                            ></b-form-select>
                            <b-form-invalid-feedback :state="ruleSelectedBrand || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>

                    <b-col cols="6">
                        <b-form-group label="Sélectionner une source" label-for="input-select-source">
                            <b-form-select
                                id="input-select-source"
                                v-model="selectedPattern"
                                :options="sortedPatterns"
                                value-field="code"
                                text-field="source"
                                :state="ruleSelectedPattern || !showFormError ? null : false"
                            ></b-form-select>
                            <b-form-invalid-feedback :state="ruleSelectedPattern || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>

                    <b-col cols="6">
                        <b-form-group label="Nom du fichier" label-for="input-name-file">
                            <b-input id="input-name-file" v-model="fileName" :state="ruleFileName || !showFormError ? null : false"></b-input>
                            <b-form-invalid-feedback :state="ruleFileName || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>

                    <b-col cols="6" class="d-flex align-items-center flex-wrap" style="margin-top: 5px">
                        <b-btn variant="primary" style="padding: 10px" @click="openFileSelection">
                            <feather-icon icon="UploadIcon" />
                            Importer un fichier
                        </b-btn>
                        <b-alert show class="ml-1 flex-grow-1 m-0 text-center" style="padding: 10px">{{ alertText }}</b-alert>
                        <b-form-file ref="file-input" v-model="file" class="d-none" accept=".zip"></b-form-file>
                        <b-form-invalid-feedback :state="ruleFile || !showFormError ? null : false"> Choisissez un fichier à importer </b-form-invalid-feedback>
                    </b-col>
                </b-row>
                <b-btn variant="success" class="d-flex ml-auto" @click="confirmBannerUpload">Ajouter</b-btn>
            </b-form>
        </b-overlay>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        props: {
            brands: {
                type: Array,
                required: true,
            },
            patterns: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                selectedBrand: '',
                selectedPattern: '',
                fileName: '',
                file: null,
                showFormError: false,
                loading: false,
            };
        },
        computed: {
            ...mapGetters({
                modalContext: 'app/modalContext',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            ruleSelectedBrand() {
                return this.selectedBrand.length > 0;
            },
            ruleSelectedPattern() {
                return this.selectedPattern?.length > 0 ? this.selectedPattern.length > 0 : console.log('Pas de code pattern !');
            },
            ruleFileName() {
                return this.fileName !== '';
            },
            ruleFile() {
                return this.file !== null;
            },

            formValid() {
                return [this.ruleSelectedBrand, this.ruleSelectedPattern, this.ruleFileName].every((val) => val === true);
            },

            alertText() {
                if (this.file) {
                    return this.file.name.length > 15 ? this.file.name.slice(0, 14) + '...zip' : this.file.name;
                }
                return 'Aucune bannière importée.';
            },

            sortedBrands() {
                return this.brands.slice().sort((a, b) => {
                    return a.label.localeCompare(b.label);
                });
            },

            sortedPatterns() {
                return this.patterns.slice().sort((a, b) => {
                    return a.source.localeCompare(b.source);
                });
            },
        },
        methods: {
            openFileSelection() {
                this.$refs['file-input'].$el.childNodes[0].click();
            },
            addBanner() {
                var data = new FormData();

                data.append('patternCode', this.selectedPattern);
                data.append('brandCode', this.selectedBrand);
                data.append('name', this.fileName);
                data.append('file', this.file);

                this.loading = true;

                this.$store
                    .dispatch('app/createBanner', data)
                    .then((res) => {
                        this.$toast(this.successToast('La bannière a été ajoutée avec succès'));
                        this.resetForm();
                        this.$emit('on-refresh');
                    })
                    .catch((e) => {
                        if (e.response && e.response.data && e.response.data.error) {
                            const errorMessage = e.response.data.error;
                            this.$toast(this.errorToast(errorMessage));
                        } else {
                            this.$toast(this.errorToast("Une erreur s'est produite"));
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },

            resetForm() {
                this.$set(this, 'file', null);
                this.fileName = '';
                this.selectedPattern = '';
                this.selectedBrand = '';
                this.showFormError = false;
            },

            confirmBannerUpload() {
                if (!this.formValid) return (this.showFormError = true);

                this.$bvModal
                    .msgBoxConfirm('Êtes-vous sûr de vouloir ajouter la bannière ?', this.modalContext())
                    .then((confirmed) => {
                        if (confirmed) this.addBanner();
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
        },
    };
</script>

<style></style>
